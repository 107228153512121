import React, { useEffect, useRef, useState } from 'react';
import './MainPage.css';

const MainPage = () => {
    const creditsRef = useRef(null);
    const [showFuckYou, setShowFuckYou] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [currentVideo, setCurrentVideo] = useState({ 
        type: 'youtube', 
        url: 'https://www.youtube.com/embed/wvYXoyxLv64?autoplay=1&mute=1' 
    });
    
    const [showBoomyGif, setShowBoomyGif] = useState(false);
    const [boomyTimeout, setBoomyTimeout] = useState(null);

    useEffect(() => {
        const credits = creditsRef.current;
        if (credits) {
            credits.style.animation = "scrollCredits 0s linear infinite";
        }
            setShowVideoModal(true);
  
    }, []);

    useEffect(() => {
        const credits = creditsRef.current;
        if (credits) {
            if (showVideoModal) {
                credits.style.animation = "none"; // Stop the animation
                credits.style.display = "none"; // Hide the credits
            }
        }
    }, [showVideoModal]);

    const closeVideoModal = () => {
        window.location.href = "https://www.google.com";
    };

    const handleBoomyHover = () => {
        if (!showBoomyGif) {
            setShowBoomyGif(true);

            // Clear any previous timeout before setting a new one
            if (boomyTimeout) {
                clearTimeout(boomyTimeout);
            }

            // Set a timeout to hide the GIF after 5 seconds
            const timeout = setTimeout(() => {
                setShowBoomyGif(false);
            }, 9000);

            setBoomyTimeout(timeout);
        }
    };

    return (
        <div className="page-container">
            {/* Header */}
            <header className="header">
                <div className="header-content">
                    <div className="header-center">
                        <img src={`${process.env.PUBLIC_URL}/MystLogo.png`} alt="Left Image" className="header-image left" />
                    </div>
                </div>
            </header>

            {/* Scrolling credits section (hidden when video appears) */}
            {!showVideoModal && (
                <div className="credits-container">
                    <div className="credits" ref={creditsRef}>
                        <h2 className="headercreds">We have chosen to prioritize our families and real-life commitments over Dragon Soul, given the game's current state of decline.</h2>

                        <h2 className="credits-title">Special Thanks</h2>
                        <p className="credits-entry">Shynie</p>
                        <p className="credits-entry">Coon</p>
                        <p className="credits-entry">Silk</p>
                        <p className="credits-entry">Rax</p>
                        <p className="credits-entry">Mellindia</p>
                        <p className="credits-entry">Hfd</p>
                        <p className="credits-entry">Cuhringe</p>
                        <p className="credits-entry">Seriouscatt</p>
                        <p className="credits-entry">Snakkeyy</p>
                        <p className="credits-entry">Linedaris</p>

                        <br />
                        <h2 className="credits-title">Thanks to</h2>
                        <p className="credits-entry">Valatix</p>
                        <br />
                        <h2 className="credits-title">Other Mentions</h2>
                        <p className="credits-entry">The Cough Guild</p>

                        {/* Hover effect for Boomy */}
                        <p className="credits-entry boomy-hover" onMouseEnter={handleBoomyHover}>
                            That Random Boomy on Ascendant Council
                        </p>

                        {/* Boomy GIF (Shows for 5 seconds when triggered) */}
                        {showBoomyGif && (
                            <img src={`${process.env.PUBLIC_URL}/boomy.gif`} alt="Boomy GIF" className="boomy-gif" />
                        )}

                        <br />
                        <h3> Best of Luck to the Raiders pushing for World First and Realm First.</h3>
                        <br />
                        <br />
                        <h2> We May Return in Mists of Pandaria</h2>
                        <br />

                    </div>
                </div>
            )}

            {/* Video Modal */}
            {showVideoModal && (
                <div className="video-modal" onClick={closeVideoModal}>
                    <div className="video-container" onClick={(e) => e.stopPropagation()}>
                        {currentVideo.type === 'youtube' && (
                            <iframe
                                width="100%"
                                height="100%"
                                src={`${currentVideo.url}?autoplay=1`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MainPage;
